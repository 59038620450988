import { useState, useEffect } from 'react';
import { FiX } from 'react-icons/fi';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../index.css';
import { config } from '../config/index';
import { FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import Logo from '../assets/Logo.svg';
import BergerMenuImage from '../assets/menu.png'
import {
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react';

type Direction = 'ltr' | 'rtl';

export const initialState: { direction: Direction } = {
  direction: 'ltr',
};

export const Context = createContext<{
  state: { direction: Direction };
  setState: Dispatch<SetStateAction<{ direction: Direction }>>;
}>({ state: initialState, setState: () => { } });

export const useGlobalState = () => useContext(Context);
interface NavbarProps {
  isArabic: boolean;
  handleLanguageChange: () => void;
}
const MOBILE_BREAKPOINT = 1024;
const DESKTOP_BREAKPOINT = 1083;
export function Navbar({ isArabic, handleLanguageChange }: NavbarProps) {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);
  const navigate = useNavigate();
  const languagePrefix = isArabic ? 'ar' : 'en';
  const [isBelow1083, setIsBelow1083] = useState(window.innerWidth < DESKTOP_BREAKPOINT);
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < MOBILE_BREAKPOINT
  );

  const { state, setState } = useGlobalState();
  const location = useLocation();
  const activeLink = location.pathname;
  const section = window.location.href

  const sectionTranslations: Record<string, string> = {
    coffeeshops: isArabic ? 'أصحاب-المقاهي' : 'coffeeshops',
    customers: isArabic ? 'محبي-القهوة' : 'customers',
    gallery: isArabic ? 'المعرض' : 'gallery',
    'become-a-partner': isArabic ? 'انضم-كشريك' : 'become-a-partner',
  };

  let links = [
    { to: `/${isArabic ? 'ar' : 'en'}`, label: isArabic ? ' الرئيسية' : 'Home', url: 'home' },
    {
      to: `#${sectionTranslations.coffeeshops}`,
      label: isArabic ? ' أصحاب المقاهي' : 'Coffee Shop',
      url: sectionTranslations.coffeeshops
    },
    { to: `#${sectionTranslations.customers}`, label: isArabic ? 'محبي القهوة' : 'Customers', url: sectionTranslations.customers },
    { to: `#${sectionTranslations.gallery}`, label: isArabic ? 'المعرض' : 'Gallery', url: sectionTranslations.gallery },
    {
      to: `#${sectionTranslations['become-a-partner']}`,
      label: isArabic ? ' انضم كشريك' : 'Become a Partner',
      url: sectionTranslations['become-a-partner']
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < MOBILE_BREAKPOINT;
      setIsMobile(isMobile);
      setIsBelow1083(window.innerWidth < DESKTOP_BREAKPOINT);
      if (!isMobile) {
        setIsNavExpanded(false);
      }
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (isArabic && !isMobile) {
    links = links.reverse();
  }
  const headerClassName = `border-gray-300 block header-background  ${isNavExpanded || 'navbar-expanded'
    } ${isArabic ? 'rtl' : ''}`;
  const backgroundClassName = `header-background ${isNavExpanded || isBurgerMenuOpen ? '' : 'navbar-expanded'
    }`;
  const headerBackgroundColor = '#E5F5F8';

  if (isArabic && sectionTranslations.gallery) {
    links = links.map(link => {
      if (link.to === `/en/${`gallery`}`) {
        return { ...link, to: `/ar/${`gallery`}` };
      }
      return link;
    });
  }
  const handleLinkClick = (linkTo: string) => {
    setIsNavExpanded(false);
    setIsBurgerMenuOpen(false);

    if (activeLink === `/${languagePrefix}/gallery`) {
      const sectionName = linkTo.startsWith('#') ? linkTo.substring(1) : '';
      const translatedSectionName = sectionTranslations[sectionName];
      if (translatedSectionName) {
        const targetSection = document.getElementById(translatedSectionName);
        if (targetSection) {
          targetSection.scrollIntoView({ behavior: 'smooth' });
        }
      }
      navigate(`/${languagePrefix}`);
    } else if (linkTo.startsWith('#')) {
      const sectionName = linkTo.substring(1);
      const translatedSectionName = sectionTranslations[sectionName];
      if (translatedSectionName) {
        const targetSection = document.getElementById(translatedSectionName);
        if (targetSection) {
          targetSection.scrollIntoView({ behavior: 'smooth' });
        }
      }
    } else {
      navigate(linkTo);
    }
  };

  return (
    <Context.Provider value={{ state, setState }}>
      <div
        style={{
          backgroundColor: '#E5F5F8',
          width: '100%',
          height: '3px',
          zIndex: '-9999',
          position: 'fixed',
        }} />
      <header
        className={headerClassName}
        style={{
          backgroundColor: headerBackgroundColor,
          boxShadow: 'none',
        }}
      >
        <div
          className={`flex items-center xl:max-w-7xl xl:mx-auto max-w-full py-5 ${backgroundClassName} flex-wrap w-full`}
          style={{
            justifyContent: isMobile ? 'space-between' : 'space-evenly',
            paddingTop: isMobile ? '2rem' : '1.25rem',
            paddingBottom: isMobile ? '1.25rem' : '1.25rem',
          }}
        >
          <Link to={`/${isArabic ? 'ar' : 'en'}`} className="text-[#0F2D47] beanz">
            <img
              src={Logo}
              width={isMobile ? 95 : 150}
              height={46}
              alt=""
              className={activeLink === '/' && isNavExpanded ? 'text-[#335A79]' : ''}
              style={{
                marginLeft: '27px'
              }}
            />
          </Link>
          <div className="flex items-center menu-icons">
            {isNavExpanded && (
              <p
                className={`lg:hidden block h-6 w-6 cursor-pointer mr-10 mt-3 text-[#335A79] ${isArabic ? 'rtl' : ''}`}
                onClick={handleLanguageChange}
                style={{
                  fontSize: isMobile ? '14px' : '17px',
                  fontFamily: isArabic
                    ? 'EngSemi'
                    : 'ArabicFontSemi'
                }}
              >
                {isArabic? 'English' : 'العربية'}
              </p>
            )}
            {isNavExpanded ? (
              <FiX
                className="lg:hidden block h-6 w-6 cursor-pointer text-[#335A79]"
                style={{
                  marginRight: '27px',
                }}
                onClick={() => setIsNavExpanded(false)}
              />
            ) : (
              <img
                src={BergerMenuImage}
                alt="Berger Menu"
                className="lg:hidden block h-[18px] w-[25px] cursor-pointer"
                onClick={() => setIsNavExpanded(true)}
                style={{
                  marginRight: '27px',
                }}
              />
            )}
          </div>
          <nav className={`${isNavExpanded ? 'block' : 'hidden'} text-center w-full lg:flex lg:items-center lg:w-auto  ${isArabic ? 'rtl' : ''}`}
            style={{
              paddingTop: isMobile ? '35px' : '20px',
              paddingBottom: isMobile ? '0px' : '20px',
            }}>
            <ul className={`text-gray-600 lg:flex lg:justify-${isArabic ? 'end' : 'between'}`}>
              {links.map((link, index) => (
                <li key={index}>
                  {link.to === `/${languagePrefix}/gallery` ? (
                    <Link
                      to={`/${languagePrefix}/gallery`}
                      className={`lg:px-5 py-2 block text-[15px] w-full ${(activeLink === link.to && link.to !== '/gallery') ||
                        link.to === activeLink ||
                        (link.to === `/${languagePrefix}/gallery` &&
                          (activeLink === `/en/gallery` || activeLink === `/ar/gallery`))
                        ? 'text-[#335A79]'
                        : 'text-[#788E9F80]'
                        } ${isNavExpanded ? 'hover:bg-[#DDF0F6]' : ''}`}
                      style={{
                        fontSize: isMobile ? '16px' : '17px',
                        fontFamily: isArabic
                          ? isMobile
                            ? 'ArabicFontSemi'
                            : 'ArabicFontSemi'
                          : isMobile
                            ? 'EngSemi'
                            : 'EngSemi',
                      }}
                    >
                      {link.label}
                    </Link>
                  ) : (
                    <a
                      href={`#${link.to.substring(1)}`}
                      className={`lg:px-5 py-2 block text-[15px] w-full  ${(!isArabic || (isArabic && isMobile)) && index === 0? 'text-[#335A79]' : isArabic && !isMobile && index === links.length - 1? 'text-[#335A79]'  : 'text-[#788E9F80]' } ${isNavExpanded ? 'hover:bg-[#DDF0F6]' : ''} `} 
                      id= {sectionTranslations[index]}
                      style={{
                        fontSize: isMobile ? '16px' : '17px',
                        fontFamily: isArabic
                          ? 'ArabicFontSemi'
                          : 'EngSemi',
                        pointerEvents: isArabic && index === links.length - 1? 'none' : !isArabic && index === 0? 'none' : 'all'
                      }}
                      
                      onClick={() => handleLinkClick(link.to)}
                    >
                      {link.label}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </nav>
          <nav className={`${isNavExpanded ? 'block' : 'hidden'} w-full lg:flex lg:items-center lg:w-auto ml-4`}>
            <div className={`${isNavExpanded ? 'hidden' : 'flex'} items-center social-icons-wrapper`}>
              {!isBelow1083 && (
                <ul className="text-base text-gray-[#335A79] lg:flex lg:justify-between flex justify-start text-[#335A79]">
                  <li className="mr-7">
                    <span onClick={handleLanguageChange}>
                      <p className={'block text-[15px] w-full'}
                       style={{
                        fontSize: isMobile ? '16px' : '17px',
                        fontFamily: isArabic
                          ? 'EngSemi'
                          : 'ArabicFontSemi'
                    }}> {isArabic? 'English' : 'العربية'} </p>
                    </span>
                  </li>
                  <li className="mr-7">
                    <a href={config.instagramUrl} target="_blank" rel="noopener noreferrer">
                      <FaInstagram size={19} />
                    </a>
                  </li>
                  <li className="mr-7">
                    <a href={config.linkedinUrl} target="_blank" rel="noopener noreferrer">
                      <FaLinkedinIn size={19} />
                    </a>
                  </li>
                </ul>
              )}
            </div>
          </nav>
        </div>
      </header>
    </Context.Provider>
  );
}